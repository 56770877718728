import { Modal } from '.'
import { pick } from 'ramda'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { Button } from '@/components/button'

import { useAnswersArePosted } from '@/util/hooks/usePostAnswers'
import { useStore, useSession } from '@/util/store'
import { useKey, useSubmitTestData, useTestComplete } from '@/util/hooks'
import { useNavigation } from '@/util/hooks/useNavigation'

import { SubmitTestModalError } from './submit-test-modal-error'
import { useProperties } from '@/util/hooks/useProperties'

export function TestEndModal() {
  // Dev note: This is a single-modal refactor that combines the following modals:
  // end-of-test, end-of-subtest, and time-limit

  const answersArePosted = useAnswersArePosted()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [noClose, setNoClose] = useState(false)
  const { t } = useTranslation(['tests', 'common'])
  const [submitTestData, submitError] = useSubmitTestData()
  const { nextSubTest } = useNavigation()
  const { onTestComplete } = useTestComplete()
  const { closeModal, modalState, isOnline } = useStore(
    pick(['closeModal', 'modalState', 'isOnline'])
  )
  const {
    activeSubTest,
    activeTest,
    activeRespondent,
    respondentToken,
    getTimeUsed,
    failedResponses,
  } = useSession(
    pick([
      'activeSubTest',
      'activeTest',
      'activeRespondent',
      'respondentToken',
      'getTimeUsed',
      'failedResponses',
    ])
  )
  const {
    isLastQuestion,
    isLastSubtest,
    isLastSection,
    canNavigatePrevious,
  } = useProperties()
  const {
    isTimeExpired = false,
    isDismissible = canNavigatePrevious,
    isTestEnd: isTestEndPassedIn,
  } = modalState || {}
  const handleKeyClose = () => {
    if (isDismissible) closeModal()
  }
  useKey('Escape', handleKeyClose)
  useKey('Enter', handleKeyClose)

  const isTestEnd =
    isTestEndPassedIn ||
    ((isLastQuestion || isTimeExpired) && isLastSubtest && isLastSection)

  const getModalText = ({
    isTestEnd,
    isTimeExpired,
    isLastQuestion,
    canNavigatePrevious,
  }) => {
    let title = ''
    if (isTimeExpired) {
      title = t('tests:modals.endOfTestTimeLimit.heading')
    } else if (isTestEnd) {
      title = t('tests:modals.endOfTest.heading')
    } else {
      title = t('tests:modals.endOfSection.heading')
    }

    let body = ''
    if (isTimeExpired && isTestEnd) {
      body = t('tests:modals.endOfTestTimeLimit.instructions')
    } else if (isTimeExpired) {
      body = t('tests:modals.testEndTimeLimit.instructions', {
        buttonText: t('common:next'),
      })
    } else if (isTestEnd && !canNavigatePrevious) {
      body = t('tests:modals.endOfTest.noEditingInstructions')
    } else if (isTestEnd) {
      body = t('tests:modals.endOfTest.instructions')
    } else if (isLastQuestion && canNavigatePrevious) {
      body = t('tests:modals.endOfSection.instructions')
    } else {
      body = t('tests:modals.endOfSection.noEditingInstructions')
    }

    return {
      title,
      body,
    }
  }

  const { title, body } = getModalText({
    isTestEnd,
    isTimeExpired,
    isLastQuestion,
    canNavigatePrevious,
  })

  async function mainAction() {
    document.activeElement.blur()
    if (isSubmitting) return
    setIsSubmitting(true)
    setNoClose(true)

    const routeToNextSubTest = () => {
      nextSubTest()
      closeModal()
    }

    const completeTest = () => {
      onTestComplete()
      closeModal()
    }

    try {
      await submitTestData({
        activeSubTest,
        respondentToken,
        timeUsed: getTimeUsed,
        unpostedResponses: failedResponses,
        isEndOfTest: isTestEnd,
        onComplete: () => (isTestEnd ? completeTest() : routeToNextSubTest()),
        ...(isTestEnd && {
          respondentId: activeRespondent.respondentId,
          assessmentId: activeTest.assessmentId,
        }),
      })
    } catch (err) {
      console.error(err)
      setTimeout(() => setIsSubmitting(false), 2000)
    }
  }

  return (
    <Modal
      variant={`medium ${noClose || !isDismissible ? 'body-no-close' : ''}`}
      hideClose={!isDismissible}
    >
      <div className='modal-header'>
        <h2 className='tc'>{parse(title)}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{parse(body)}</p>
        <SubmitTestModalError
          isSubmittingAnswers={!answersArePosted}
          submitError={submitError}
          submitButtonLabel={
            isTestEnd
              ? t('common:submit').toUpperCase()
              : t('common:next').toUpperCase()
          }
        />
      </div>
      <div className='modal-actions'>
        {isDismissible && (
          <button onClick={closeModal} className='btn btn-outline'>
            {t('common:continueEditing')}
          </button>
        )}
        <Button
          onClick={mainAction}
          isSubmitting={isSubmitting}
          disabled={!isOnline || isSubmitting || !answersArePosted}
          className='btn'
        >
          {isTestEnd ? t('common:submit') : t('common:next')}
        </Button>
      </div>
    </Modal>
  )
}
