import styles from './respondent-completion-modal.module.scss'
import { Modal } from '@/components/modals'
import { useStore } from '@/util/store'
import classnames from '@/util/classnames'

export const RespondentCompletionModal = () => {
  const { heading, content, footer } = useStore((state) => state.modalState)

  return (
    <Modal hideClose={true} variant='large body-no-close'>
      <div className='modal-content'>
        <p className={styles['heading']}>{heading}</p>
        <div
          className={styles['content']}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
      <div
        className={classnames(['modal-actions', styles.footer])}
        dangerouslySetInnerHTML={{
          __html: footer,
        }}
      ></div>
    </Modal>
  )
}
