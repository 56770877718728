import { useCallback } from 'react'

import { put } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { useSession } from '@/util/store'
import { useProperties } from '@/util/hooks/useProperties'
import { useMutation } from 'react-query'

export function useBookmarking() {
  const respondentToken = useSession((s) => s.respondentToken)
  const setLastActiveTime = useSession((s) => s.setLastActiveTime)
  const resetTimer = useSession((s) => s.resetTimer)
  const isTimerStarted = useSession((s) => s.isTimerStarted)
  const startSessionTimer = useSession((s) => s.startTimer)
  const { isTimedTestWide } = useProperties()

  const setSubtestBookmark = useCallback(
    async (subtestId) => {
      try {
        await put(
          `${apiUrl('subTest')}${subtestId}/bookmark/set-subtest`,
          null,
          {
            respondentToken,
          }
        )
        !isTimedTestWide && resetTimer()
        return
      } catch (error) {
        console.error('Failed to set subtest bookmark')
        throw error
      }
    },
    [respondentToken, resetTimer]
  )

  const startTimer = useCallback(
    async (subtestId) => {
      try {
        await put(
          `${apiUrl('subTest')}${subtestId}/bookmark/start-timer`,
          null,
          {
            respondentToken,
          }
        )
        startSessionTimer()
      } catch (error) {
        console.error('Failed to start timer')
        throw error
      }
    },
    [isTimerStarted, startSessionTimer, respondentToken, setLastActiveTime]
  )

  const updateLastActive = useCallback(
    // post for updating the respondent's last active time to the current time

    async (subtestId) => {
      try {
        await put(
          `${apiUrl('subTest')}${subtestId}/bookmark/last-active-time`,
          null,
          {
            respondentToken,
          }
        )

        setLastActiveTime(Date.now())
      } catch (error) {
        console.error('Failed to set last active time')
        throw error
      }
    },
    [respondentToken, setLastActiveTime]
  )

  const resumeTimer = useMutation(
    () =>
      put(`${apiUrl('bookmark')}/resume-timer`, null, {
        respondentToken,
      }),
    {
      onSuccess: (data) => {
        startSessionTimer()
      },
    }
  )

  return {
    setSubtestBookmark,
    startTimer,
    updateLastActive,
    resumeTimer,
  }
}
