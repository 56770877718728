import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { TextInput } from '@/components/text-input'
import { Button } from '@/components/button'
import { showNotification } from '@/components/notification'
import { Modal } from '..'

import { useStore } from '@/util/store'
import { useOrganization } from '@/util/hooks'
import { SelectUserTypeGroup } from './select-user-type'

export function AddNewTeamMemberModal() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()
  const { inviteOrgMember } = useOrganization()
  const closeModal = useStore((state) => state.closeModal)
  const { t, roles } = useStore((state) => state.modalState)
  const [selectedRole, setSelectedRole] = useState(roles[0])
  const email = watch('email')

  const onSubmit = async (data) => {
    const onSuccess = () => {
      showNotification({
        message: `Successfully invited ${data.email}`,
        type: 'success',
      })
      closeModal()
    }
    const onError = (error) => {
      if (error.code === 400) {
        if (
          error.data.email &&
          error.data.email[0] === 'user with this email already exists.'
        )
          showNotification({
            message: 'A user with this email address already exists.',
            type: 'error',
          })
      } else {
        showNotification({
          message: 'An error occurred while inviting a team member.',
          type: 'error',
        })
      }
    }
    const body = { ...data, role: selectedRole }

    try {
      await inviteOrgMember({
        body,
        onSuccess,
        onError,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-content'>
          <h1>New Team Member</h1>
          <p>
            Add a new team member by email address. We’ll send an invitation to
            complete their account.
          </p>
          <TextInput
            name='email'
            type='email'
            label='Email Address'
            placeholder='email@email.com'
            register={register}
            required
            errors={errors}
            pattern={{
              value: /\S+@\S+.\S+/,
            }}
          />

          <SelectUserTypeGroup
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            roles={roles}
            translationFn={t}
          />
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={() => closeModal()}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button
            className='btn'
            disabled={!email || !selectedRole || isSubmitting}
            isSubmitting={isSubmitting}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}
