import { useEffect, useState } from 'react'

import { getImageSources, loadImage } from '@/util/image-preload'

/**
 * A hook for preloading images - used to preemptively request images so that the browser will cache them
 * @param {object} subtest The subtest for which to load images
 * @returns An object containing:
 *   - `loadImages` function to retry image loading if it happens to fail
 *   - `isError` true if an error was encountered on preload
 *   - `isLoading` true if preloading is in progress
 *   - `loadCount` number of images which have finished loading
 *   - `totalCount` total number of images to preload
 */
export const useImagePreload = (subtest) => {
  const images = getImageSources(subtest)
  const [loadCount, setLoadCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const loadImages = async () => {
    setIsLoading(true)
    setLoadCount(0)
    const onLoad = () => setLoadCount((count) => count + 1)

    try {
      const p = await Promise.all(
        images.map((src) => loadImage(src).then(onLoad))
      )
      setIsError(false)
      return p
    } catch (err) {
      const imageError = new Error(`image failed to load: ${err.target?.src}`)
      console.log(imageError)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadImages()
  }, [subtest])

  return {
    loadImages,
    isError,
    isLoading,
    loadCount,
    totalCount: images.size,
  }
}
