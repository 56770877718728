import { subtestTypes } from '@/util/consts'

export const loadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(src)
    img.onerror = (err) => reject(err)
    img.src = src
  })

export const getImageSources = (subtest) => {
  if (!subtest || !subtest.subtestQuestions) return []

  const images = []
  subtest.subtestQuestions.forEach((q) => {
    const question = q.question

    if (question.type == subtestTypes.symbol_search) {
      const characters = [...question.bodyText.replace(/\s/g, '')]
      question.answers
        .filter((answer) => answer.text != 'None')
        .forEach((answer) => characters.push(answer.text))
      characters.forEach((char) =>
        images.push(`/images/tests/symbols/${char}.png`)
      )
    }

    if (question.type == subtestTypes.digit_symbol_coding) {
      const characters = [...question.bodyText.replace(/\s/g, '')]
      characters.forEach((char) =>
        images.push(`/images/tests/symbols/${char}.png`)
      )
    }

    if (question.type == subtestTypes.cancellation) {
      const imgCodes = question.bodyText.split(',')
      question.answers.forEach((answer) => imgCodes.push(answer.text))
      imgCodes.forEach((code) =>
        images.push(`/images/tests/cancellation/${code}.png`)
      )
    }

    question.questionLegendImages.forEach((img) => {
      images.push(img.imageData.image)
    })

    question.questionBodyImages.forEach((img) => {
      images.push(img.imageData.image)
    })

    question.answers.forEach((a) => {
      a.answerImages.forEach((img) => {
        images.push(img.imageData.image)
      })
    })
  })

  return images
}

const getTestImageSources = (test) => {
  const images = []
  for (let section of test.sections) {
    for (let subtest of section.subtests) {
      images.push(...getImageSources(subtest))
    }
  }

  return images
}

export const loadAllTestImages = async ({
  test,
  setImageLoadCount,
  setImageTotalCount,
}) => {
  const images = getTestImageSources(test)
  setImageLoadCount && setImageLoadCount(0)
  setImageTotalCount && setImageTotalCount(images.length)

  const onLoad = () =>
    setImageLoadCount && setImageLoadCount((count) => count + 1)

  try {
    const p = await Promise.all(
      Array.from(images).map((src) => loadImage(src).then(onLoad))
    )
    return p
  } catch (err) {
    const imageError = new Error(`image failed to load: ${err.target?.src}`)
    console.log(imageError)
    throw err
  }
}
