import { useQuery } from 'react-query'

import { get } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { getAccessToken } from '@/util/jwtTokens'

export function useTests() {
  const { data: tests } = useQuery('tests', async () => {
    const token = await getAccessToken()
    return get(apiUrl('tests'), { token })
  })

  return { tests: tests && tests.results }
}
