import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import { Modal } from '.'
import { Button } from '@/components/button'
import { showNotification } from '@/components/notification'
import { useSession, useStore } from '@/util/store'
import { useAnswersArePosted } from '@/util/hooks/usePostAnswers'
import { useProperties } from '@/util/hooks/useProperties'

export function StopAssessmentModal() {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { canNavigateSubtests } = useProperties()
  const closeModal = useStore((state) => state.closeModal)
  const isOnline = useStore((state) => state.isOnline)
  const reentryCode = useSession((state) => state.reentryCode)
  const answersArePosted = useAnswersArePosted()
  const { t } = useTranslation(['tests', 'common'])
  const endSession = async () => {
    try {
      setIsSubmitting(true)
      router.replace(`/tests`)
      closeModal()
    } catch (error) {
      if (error.code === 500) {
        showNotification({
          type: 'error',
          message: t('errors.nonSpecificTryAgain'),
        })
      }
      setIsSubmitting(false)
    }
  }

  return (
    <Modal variant='medium'>
      <div className='modal-header'>
        <h2 className='tc'>{t('modals.stopAssessment.title')}</h2>
      </div>
      <div className='modal-content'>
        <p className='tl'>{t('modals.stopAssessment.stopInstructions')}</p>
        {!isOnline && canNavigateSubtests && (
          <>
            <p className='tl'>
              {parse(t('modals.stopAssessment.offlineStatus'))}
            </p>
            <p className='tl strong'>
              {parse(t('modals.stopAssessment.offlineInstructions'))}
            </p>
          </>
        )}
        <p className='tl'>{t('modals.stopAssessment.resumeInstructions')}</p>
        <p className='tl'>
          <strong>
            {t('common:reentryCode')}: {reentryCode}
          </strong>
        </p>
      </div>
      <div className='modal-actions'>
        <button
          type='button btn-outline'
          onClick={() => {
            closeModal()
          }}
          className='btn btn-outline'
        >
          {t('common:backToAssessment')}
        </button>
        <Button
          onClick={endSession}
          isSubmitting={isSubmitting}
          className='btn'
          disabled={!answersArePosted}
        >
          {t('common:endSession')}
        </Button>
      </div>
    </Modal>
  )
}
